// Component states
import states from './settings-image-modifier.state.js'
import workspace from '@/components/parts/workspace/workspace.state'

// Services
import { landingPageMode, lockEditPanel } from '@/services/states/states.service'

// Components
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'
import MdiInformationOutline from 'vue-material-design-icons/InformationOutline.vue'
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'
import ActionImageModifier from '../actions-image-modifier/Actions-image-modifier.vue'

// Library
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-mj-image'

// Properties
const props = {
  conf: Object,
  mjml: Object,
  show: Boolean
}

// Vue@data
const data = function () {
  return {
    states: _cloneDeep(states),
    workspace
  }
}
// Vue@data

// Components
const components = {
  ImagePathModifier,
  ActionImageModifier,
  MdiInformationOutline,
  AlignHorizontalContentModifier
}

// Methods
const methods = {
  /**
   * Generate image with correct size
   * @param {String} src (url)
   */
  _setImageProperties (src) {
    const img = new Image()
    img.onload = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'maxWidth', img.width > bodySize ? bodySize : img.width)
      this.$set(this.states, 'imageWidth', img.width)
      if (this.states.maxWidth < this.states.tempWidth) {
        this.$set(this.states, 'tempWidth', this.states.maxWidth)
      }
    }
    img.onerror = () => {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      this.$set(this.states, 'imageWidth', null)
      this.$set(this.states, 'maxWidth', bodySize)
      this.$set(this.states, 'tempWidth', this.states.maxWidth)
    }
    img.src = src
  }
}

// Computed Methods
const computed = {

  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  },

  /**
   * Check if fallback is active
   */
  hasFallbackActive: {
    // getter
    get () {
      return !this.mjml.attributes['css-class'].includes('fallback-desktop-false')
    },
    // setter
    set (newValue) {
      const updatedClass = this.mjml.attributes['css-class'].replace(
        `fallback-desktop-${!newValue}`, 
        `fallback-desktop-${newValue}`
      )
      this.mjml.attributes['css-class'] = updatedClass

      if (newValue) {
        this.mjml.attributes.src = this.mjml.children[0].attributes.src
      } else {
        this.mjml.attributes.src = ''
        this.mjml.attributes.href = ''
        lockEditPanel(false)
      }
    }
  },

  manualWidth: {
    // getter
    get () {
      return this.states.widthManual
    },
    // setter
    set (newValue) {
      this.states.widthManual = newValue
      this.mjml.attributes.width = newValue ? `${parseInt(this.states.tempWidth)}px` : ''
    }
  },

  TitleText: {
    // getter
    get () {
      return this.mjml.attributes.title.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.mjml.attributes, 'title', val)
    }
  },

  alternateText: {
    // getter
    get () {
      return this.mjml.attributes.alt.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.mjml.attributes, 'alt', val)
    }
  },

  width: {
    // getter
    get () {
      const bodySize = parseInt(workspace.template.children[0].attributes.width)
      if (this.mjml.attributes.width !== '') {
        this.states.tempWidth = this.mjml.attributes.width
      }

      const width = this.states.widthManual
        ? this.mjml.attributes.width || bodySize
        : this.states.tempWidth

      return parseInt(width, 10)
    },
    // setter
    set (newValue) {
      if (!this.manualWidth) return
      this.mjml.attributes.width = `${newValue}px`
    }
  }
}

const watch = {
  'mjml.attributes.src': function (newSrc) {
    this._setImageProperties(newSrc)
  }
}

/**
 * Init widthAuto state
 */
function initState () {
  if (!this.mjml.attributes.width) {
    this.states.widthManual = false
  }

  this._setImageProperties(this.mjml.attributes.src)
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  created: initState
}
